@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

.top-bar, .top-bar-mobile{
    align-items: center;
    padding: 1vh 2vw;
    background-color: #000000;
    color: white;
}
.top-bar {
    display: flex;
}
.top-bar-mobile {
    display: none;
}

.top-btn {
    color: white;
    background-color: black;
 }

.top-btn:hover {
     text-decoration: none;
     cursor: pointer;
}

.nav-links {
    font-family: 'Dela Gothic One', sans-serif;
    font-size: max(16px, 0.7vmax);
    padding: 10px;
}

.icon {
    color: white;
    margin-right: 0;
    margin-left: 0;
}


.navigation {
    margin-left: auto;
    margin-right: 0;
}

.popupmenu {
    z-index: 10;
    display: flex;
    position: absolute;
    background-color: black;
    color: white;
    padding: 10px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.bi-calendar3 {
    font-size: max(20px, 0.7vmax);
}



