@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap);
* {
    font-family: Arial;
}

body {
    background-color: #f5f5f5;
}

button {
    border: none;
    outline: 1px;
    background-color: Transparent;
    padding: 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

input {
    padding: 2px;
}

.heading1 {
    font-size: max(2.1vmax, 24px);
    padding-right: 0.5em;
    font-family: 'Dela Gothic One', cursive;
}

.heading2 {
    font-size: max(1.8vmax, 20px);
}

.heading3 {
    font-size: max(1vmax, 16px);
    font-weight: 200;
}

.title-bar {
    display: flex;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
}

.content {
    margin: 2vh 3vw;
}


.title {
    margin-left: 0;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.tools {
    margin-left: auto;
    margin-right: 0;
    display: flex;
}

.header-component {
    width: 50%;
}


.index-button {
  background-color: #f3f6f4;
  color: black;
  cursor: pointer;
  padding: 18px;
  text-align: center;
  border: none;
  outline: none;
  font-size: 20px;
  margin: 2vh;
  font-family: 'Dela Gothic One', cursive;
  }

.index-button:hover {
    background-color: #fdc781;
}

.edit-buttons {
    margin: 5px;
    padding: 0px;
}

.item-button {
    width: 100%;
    height:100%;
    text-align: left;
    font-size: max(1vw, 16px);
    background-color: Transparent;
    outline: none;
    border: none;
    color: black;
    font-weight: bold;
    margin: 5px;

}

.item-button:hover {
    background-color:rgba(0, 0, 0, 0.1);
}



.forms {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-around;
}


@media (max-width: 800px) {
  .forms {
    flex-direction: column;
  }
}

.column {
    flex: 25% 1;
}


.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 2vw;
    margin-right: 2vw;
}




a:link {
    color: black;
    text-decoration: none;
}

a:visited {
    color: black;
}

a:hover {
    color: grey;
    text-decoration: underline;
}

.fc {
    max-height: 100vh;
    max-width: 100vw;
    padding: 10px;
    z-index: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login-page {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.login {
    background: lightblue;
    padding: 20px;
    margin: auto auto;
}


.top-bar, .top-bar-mobile{
    align-items: center;
    padding: 1vh 2vw;
    background-color: #000000;
    color: white;
}
.top-bar {
    display: flex;
}
.top-bar-mobile {
    display: none;
}

.top-btn {
    color: white;
    background-color: black;
 }

.top-btn:hover {
     text-decoration: none;
     cursor: pointer;
}

.nav-links {
    font-family: 'Dela Gothic One', sans-serif;
    font-size: max(16px, 0.7vmax);
    padding: 10px;
}

.icon {
    color: white;
    margin-right: 0;
    margin-left: 0;
}


.navigation {
    margin-left: auto;
    margin-right: 0;
}

.popupmenu {
    z-index: 10;
    display: flex;
    position: absolute;
    background-color: black;
    color: white;
    padding: 10px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.bi-calendar3 {
    font-size: max(20px, 0.7vmax);
}




.priority {
    padding: 20px;
    margin: 20px;
    min-width: 330px;
    min-height: 330px;
    max-width: 30vw;
    box-shadow: 3px 4px 3px #b3b3b3;
    resize: both;
    overflow: auto;
}

.priority-header {
    height: 10%;
    margin-bottom: 10px;
}

.header-text {
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
}

.item-tools {
    position: relative;
    overflow: auto;
    right: 0;
    left: auto;
}

.label {
    font-size: max(0.8vw, 13px);
    font-weight: 200;
}


