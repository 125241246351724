.login-page {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.login {
    background: lightblue;
    padding: 20px;
    margin: auto auto;
}

