@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

.priority {
    padding: 20px;
    margin: 20px;
    min-width: 330px;
    min-height: 330px;
    max-width: 30vw;
    box-shadow: 3px 4px 3px #b3b3b3;
    resize: both;
    overflow: auto;
}

.priority-header {
    height: 10%;
    margin-bottom: 10px;
}

.header-text {
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
}

.item-tools {
    position: relative;
    overflow: auto;
    right: 0;
    left: auto;
}

.label {
    font-size: max(0.8vw, 13px);
    font-weight: 200;
}

